import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import LoginService from "../../services/LoginService";
import BuildService from "../../services/BuildService";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getUserConnected } from "../../redux/actions/DashboardActions";
import "./style.css";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
// import { useRecaptcha } from "react-hook-recaptcha";
import { isMobile } from "react-device-detect";
import ResetPasswordForm from "./ResetPasswordForm";
import { STYLE_INPUT } from "../../utilis/const";


function SignIn(props) {
    const history = useHistory();
    //const [cookies, setCookie, removeCookie] = React.useCookies(["user"]);
    const [loading, setLoading] = useState(false);
    const [showMobileContent, setShowMobileContent] = useState(false);
    const [showResetContent, setShowResetContent] = useState(false);
    const [showPassword, setshowPassword] = useState("password");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [cookies, setCookie, removeCookie] = useCookies();
    const successCallback = (response) =>
        handleSubmit((data) =>
            handleLogin({ ...data, catchaResponse: response })
        )();
    // const { recaptchaLoaded, recaptchaWidget } = useRecaptcha({
    //     containerId,
    //     successCallback,
    //     sitekey,
    //     size: "invisible",
    // });
    const dispatch = useDispatch();

    React.useEffect(() => {
        removeCookie("intercom-session-nhu68eka");
    }, []);

    const Redericet = async(data)=>{
                                         window.analytics.track("Sign in", {
                                                userID: localStorage.getItem("userId"),
                                                companyID: localStorage.getItem("companyId"),
                                                companyName: localStorage.getItem("companyName"),
                                                firstName: localStorage.getItem("firstName"),
                                                lastName: localStorage.getItem("lastName"),
                                                email: data?.login,
                                            });
                                            window.analytics.identify(localStorage.getItem("userId"), {
                                                firstName: localStorage.getItem("firstName"),
                                                lastName: localStorage.getItem("lastName"),
                                                email: data?.login,
                                            });
                                            window.analytics.page();
                                            localStorage.setItem("isAuthenticated", "true");
                                            await dispatch(getUserConnected());
                                            await BuildService.getApiKeys();
                                            if (isMobile) {
                                                setShowMobileContent(true);
                                            } else {
                                                setShowMobileContent(false);
                                                window.location =
                                                    window.location.protocol +
                                                    "//" +
                                                    window.location.host +
                                                    "/home";
                                            }
                                        console.log("success login")
                                        setLoading(false);
    }

    const handleLogin = async (data) => {
        setLoading(true);
        var res = await LoginService.signIn(data);
        try {
            if (res.status === 200) {
               Redericet(data)  
            } else {
                setLoading(false);
                toast.error(res?.message);
            }
        } catch (err) {
            setLoading(false);
            toast.error("Server error");
        }
    };
    // const executeCaptcha = (e) => {
    //     e.preventDefault();
    //     if (recaptchaWidget !== null) {
    //         window.grecaptcha.reset(recaptchaWidget);
    //         window.grecaptcha.execute(recaptchaWidget);
    //     }
    // };

    const renderContent = () => {
        if (showMobileContent) {
            return (
                <div
                    data-aos-easing="ease-out-quad"
                    data-aos-duration="700"
                    data-aos-delay="0"
                >
                    <section className="bg-gray-200">
                        <div className="container d-flex flex-column">
                            <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                                <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                    <h1 className="mb-0 fw-bold text-center">
                                        This content is available only on
                                        browser{" "}
                                    </h1>

                                    <p className="mb-6 text-center text-muted"></p>
                                    {/* <div
                                        id={containerId}
                                        // style={{ marginBottom: "200000px" }}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            );
        } else {
            return (
                <>
                    <Helmet>
                        <title> Sign in</title>
                    </Helmet>
                    <div
                        data-aos-easing="ease-out-quad"
                        data-aos-duration="700"
                        data-aos-delay="0"
                    >
                        <section className="bg-gray-200">
                            <div className="container d-flex flex-column">
                                <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                                    <div className="col-12-sign-in col-md-5 col-lg-5 py-8 py-md-11">
                                        {showResetContent ? (
                                            <ResetPasswordForm
                                                setShowResetContent={
                                                    setShowResetContent
                                                }
                                            />
                                        ) : (
                                            <>
                                                <h1 className="mb-0 fw-bold text-center">
                                                    Sign in
                                                </h1>
                                                <form
                                                    className="mb-6"
                                                    onSubmit={handleSubmit(
                                                        handleLogin
                                                    )}
                                                >
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="email"
                                                        >
                                                            Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control2"
                                                            name="login"
                                                            id="email"
                                                            placeholder="name@address.com"
                                                            autoComplete="off"
                                                            {...register(
                                                                "login"
                                                            )}
                                                        />
                                                        {errors?.login && (
                                                            <small
                                                                id="emailHelp"
                                                                className="text-danger form-text"
                                                            >
                                                                {
                                                                    errors
                                                                        ?.login
                                                                        ?.message
                                                                }
                                                            </small>
                                                        )}
                                                    </div>
                                                    <div className="form-group mb-5">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="password"
                                                        >
                                                            Password
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                className="form-control"
                                                                type={
                                                                    showPassword
                                                                }
                                                                name="password"
                                                                id="password"
                                                                placeholder="Enter your password"
                                                                autoComplete="off"
                                                                {...register(
                                                                    "password"
                                                                )}
                                                                style={{
                                                                    ...STYLE_INPUT,
                                                                }}
                                                            />
                                                            <span
                                                                className="input-group-text"
                                                                style={{
                                                                    border: "none",
                                                                }}
                                                            >
                                                                {showPassword ===
                                                                "password" ? (
                                                                    <i
                                                                        className="fa fa-eye"
                                                                        id="togglePassword"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            setshowPassword(
                                                                                "text"
                                                                            )
                                                                        }
                                                                    ></i>
                                                                ) : (
                                                                    <i
                                                                        className="fas fa-eye-slash"
                                                                        id="togglehidePassword"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            setshowPassword(
                                                                                "password"
                                                                            )
                                                                        }
                                                                    ></i>
                                                                )}
                                                            </span>
                                                        </div>
                                                        {errors.password && (
                                                            <small
                                                                id="emailHelp"
                                                                className="text-danger form-text"
                                                            >
                                                                {
                                                                    errors
                                                                        .password
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </div>
                                                    <button
                                                        className="btn2 w-100 btn-primary2"
                                                        type="submit"
                                                        disabled={loading}
                                                    >
                                                        Sign in
                                                        {loading && (
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                    </button>
                                                    {/* <div
                                                id={containerId}
                                                // style={{ marginBottom: "200000px" }}
                                            /> */}
                                                </form>
                                                {/* <p className="mb-0 fs-sm text-center text-muted">
                                                    Don't have an account yet{" "}
                                                    <a href="/sign-up">
                                                        Sign up
                                                    </a>
                                                    .
                                                </p> */}
                                                <p className="mb-0 fs-sm text-center text-muted">
                                                    <Link
                                                        onClick={() => {
                                                            setShowResetContent(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        Don't remember your
                                                        password?
                                                    </Link>
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            );
        }
    };

    return <>{renderContent()}</>;
}
export default SignIn;
const schema = yup.object().shape({
    login: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
    password: yup.string().required("Password is required"),
});
